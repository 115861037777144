<template>
	<div>
		<div class="head">
			<div class="text">
				<img class="logo" :src="logo" alt="">
				<div class="title">{{title}}</div>
			</div>
			<img :src="topImg" alt="">
		</div>
		<div>
			<div class="one">
				<div class="but" v-for="(item,index) in navList" :key="index"
					@click="goDingyue(index + 1,item.name,item)">
					<div class="name">{{item.name}}</div>
					<img class="back" src="../../assets/eventtheme/but.png" alt="">
				</div>
			</div>
		</div>
		<div class="return" @click="homepage">
			<img src="../../assets/image/logo.png" alt="">
			<p class="home">返回首页</p>
		</div>
		<div v-if="display">
			<div class="back-box" style="min-height: 459PX;">
				<div class="one-back" style="padding-bottom: 200PX;">
					<img src="../../assets/eventtheme/spot.png" class="spot" alt="">
					<div class="lun" v-if="cards && cards.length > 0">
						<carousel :imgList="cards" :type="1" width="1200" height="459PX" borderradius="16PX" @getclick="jump" background="rgb(0, 0, 0,0.38)"></carousel>
					</div>
					<img src="../../assets/eventtheme/spot.png" class="spot2" alt="">
					<div :style="{'height': cards && cards.length > 0 ? 0:'72PX'}"></div>

					<div class="content" v-if="allData && allData.length > 0" v-for="(item,index) in allData"
						:key="index">
						<div v-for="(res,index1) in item" :key="index1"
							v-if="item && item.length > 0 && res.name != '精彩瞬间' && res.name != '赛程安排' & res.name != '精彩图集' ">
							<div style="display: flex;justify-content: space-between;">
								<div class="title-but">
									<p class="name">{{res.name}}</p>
									<img src="../../assets/eventtheme/title.png" class="title" alt="">
								</div>
								<div class="more">
									<p class="name" @click="goDingyue(index + 1,res.name,res)">更多</p>
									<p class="right">></p>
								</div>
							</div>
							<div class="line"></div>
							<div class="specific">
								<ul>
									<li v-for="(ess,index3) in res.articles" :key="index3">
										<div class="rounddot">
											<div class="round"></div>
											<a @click="jump(ess.id)">
												{{ess.title}}
											</a>
										</div>
										<span>[{{ess.publishTime | format}}]</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div style="margin-top: 102PX;" v-if="tableData && tableData.length > 0">
				<div class="one">
					<div class="but">
						<div class="name">获奖情况统计</div>
						<img class="back" src="../../assets/eventtheme/but.png" alt="">
					</div>
				</div>
				<div class="back-box">
					<div class="one-back">
						<div class="table2">
							<el-table :data="tableData" border style="width: 100%;"
								:header-cell-style="{ background: 'rgba(0, 129, 245, 0.62)' }"
								:row-class-name="tableRowClassName">
								<el-table-column label="排名" align="center">
									<template slot-scope="scope">
										<div v-if="scope.$index == 0" class="custom-header"><img src="../../assets/eventtheme/one.png" alt=""></div>
										<div v-else-if="scope.$index == 1" class="custom-header"><img src="../../assets/eventtheme/two.png" alt=""></div>
										<div v-else-if="scope.$index == 2" class="custom-header"><img src="../../assets/eventtheme/three.png" alt=""></div>
										<div v-else>
											{{ scope.$index + 1 }}
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="areaName" label="代表队" align="center">
								</el-table-column>
								<el-table-column prop="first" label="金牌" align="center">
								</el-table-column>
								<el-table-column prop="second" label="银牌" align="center">
								</el-table-column>
								<el-table-column prop="third" label="铜牌" align="center">
								</el-table-column>
								<el-table-column prop="total" label="奖牌总数" align="center">
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
			</div>

			<div style="margin-top: 102PX;" v-if="scheduleList && scheduleList.length > 0">
				<div class="one">
					<div class="but">
						<div class="name">赛程安排</div>
						<img class="back" src="../../assets/eventtheme/but.png" alt="">
					</div>
				</div>
				<div class="back-box">
					<div class="one-back">
						<div class="table">
							<el-table :data="scheduleList" border style="width: 100%;"
								:header-cell-style="{ background: 'rgba(0, 129, 245, 0.62)' }"
								:row-class-name="tableRowClassName">
								<el-table-column prop="name" label="赛事名称" align="center">
								</el-table-column>
								<el-table-column prop="address" label="地点" align="center">
								</el-table-column>
								<el-table-column prop="createTime" label="时间" align="center">
									<template slot-scope="scope">
										<div v-if="scope.row.isJSON">
											<div v-for="(item,index) in scope.row.start_time" >
											  {{getTime(item)}}
											</div>
										</div>
										<div v-if="!scope.row.isJSON">
											{{scope.row.start_time | format}}
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
			</div>

			<div style="margin-top: 102PX;" v-if="momentsList && momentsList.length > 0">
				<div class="one">
					<div class="but">
						<div class="name">精彩瞬间</div>
						<img class="back" src="../../assets/eventtheme/but.png" alt="">
					</div>
				</div>
				<div class="back-box">
					<div class="one-back"
						style="padding-top: 117PX;padding-bottom: 100PX;padding-left: 40PX;padding-right: 40PX; width: 1500PX;">
						<!-- 轮播图 -->
						<div class="swiper" v-if="momentsList.length>0">
							<img src="../../assets/eventtheme/theleftside.png" class="theleftside" alt="">
							<swiper :options="swiperOption" ref="mySwiper">
								<swiper-slide v-for="(item,index) in momentsList" :key="index">
									<div class="information" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave"
										@click="jump(item.id)">
										<div class="title">{{item.title}}</div>
										<img class="img" :src="item.imgUrl" alt="">
									</div>
								</swiper-slide>
							</swiper>
							<img src="../../assets/eventtheme/therightside.png" class="therightside" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div>
				<div class="back-box" style="padding-top: 100PX;">
					<div class="content">
						<div>
							<div style="display: flex;justify-content: space-between;">
								<div class="title-but">
									<p class="name">{{concentratetitle}}</p>
									<img src="../../assets/eventtheme/title.png" class="title" alt="">
								</div>
							</div>
							<div class="line"></div>
							<div class="specific" v-if="concentrateList && concentrateList.length > 0">
								<ul>
									<li v-for="(res,index) in concentrateList" :key="index">
										<div class="rounddot">
											<div class="round"></div>
											<a @click="jump(res.id)">
												{{res.title}}
											</a>
										</div>
										<span>[{{res.publishTime | format}}]</span>
									</li>
								</ul>
								<div class="paging" v-if="concentrateList.length > 0">
									<pagination :total="total" @currentchange="currentchange" :go="10" :pageSize="10"></pagination>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	// import 'swiper/css/swiper.min.css'
	import carousel from '@/components/carousel.vue'
	import Vue from 'vue'
	import Meta from 'vue-meta'
	Vue.use(Meta)
	export default {
		name: 'eventTheme',
		components: {
			Swiper,
			SwiperSlide,
			carousel
		},
		data() {
			return {
				swiperOption: {
					slidesPerView: 5, // 一屏显示的slide个数
					centeredSlides: true, // 居中的slide是否标记为active，默认是最左active,这样样式即可生效
					slideToClickedSlide: true, // 点击的slide会居中
					loop: true, // 循环播放, 可有无限滚动效果，初始加载即是滚动后的效果
					autoplay: {
						delay: 2000, //秒
						stopOnLastSlide: false,
						disableOnInteraction: false, //滑动不会失效
					},
					on: {
						// 该方法中的this都指代swiper本身
						tap: function() {
							console.log('点击的位置', this.activeIndex);
						}
					},
					navigation: {
						//下一张
						nextEl: '.therightside',
						//上一张
						prevEl: '.theleftside'
					}
				},

				display: true,
				cards: [],
				allData: [],
				concentratetitle: "",
				momentsList: [],
				navList: [],
				concentrateList: [],
				total: 0,
				info: {
					id: '',
					pageNum: 1,
					pageSize: 10,
				},
				scheduleList: [],
				topImg: '',
				logo:'',
				title:'',
				tableData:[]
			}
		},
		created() {
			this.logo = this.$route.query.logo
			this.title = this.$route.query.title
			
			document.title = this.$route.query.title + '-北京市体育竞赛管理和国际交流中心'
			this.$meta().refresh()
			
			if (!localStorage.getItem('isReloaded')) {
				localStorage.setItem('isReloaded', true);
				location.reload();
			} else {
				localStorage.removeItem('isReloaded');
			}
			this.$store.commit("EXIT_STATIONID", this.$route.query.id)
			this.getIndexImgList()
			this.getNav()
			this.getIndexColumn()
			this.getTopBanner()
			this.matchList()
			this.medalRankingList()
		},
		activated() {
			this.$store.commit("EXIT_STATIONID", this.$route.query.id)
		},
		mounted() {
			
		},
		computed: {
			mySwiper() {
				return this.$refs.mySwiper
			},
			getNowDate() {
				const timeOne = new Date()
				const year = timeOne.getFullYear()
				let month = timeOne.getMonth() + 1
				let day = timeOne.getDate()
				month = month < 10 ? '0' + month : month
				day = day < 10 ? '0' + day : day
				const NOW_MONTHS_AGO = `${year}-${month}-${day}`
				return NOW_MONTHS_AGO
			}
		},
		filters: {
			format(x) {
				if(x != null && x != ''){
					return x.split(" ")[0]
				}
			}
		},
		methods: {
			medalRankingList() {
				this.$api.medalRankingList(this.$route.query.id).then(res => {
					this.tableData = res.data.data.rankingList
					this.statisticalTime = res.data.data.statisticalTime
				})
			},
			getIndexImgList() {
				this.$api.getIndexImgList().then(res => {
					this.cards = res.data.data
				})
			},
			getTopBanner() {
				this.$api.getTopBanner({
					type: '1'
				}).then(res => {
					this.topImg = res.data.data[0].imgUrl
				})
			},
			getNav() {
				this.$api.getNav().then(res => {
					this.navList = res.data.data
				})
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (String(rowIndex / 2).indexOf(".") > -1) {
					return 'success-row';
				}
				return '';
			},
			/** 鼠标移入 */
			onMouseEnter() {
				// this.mySwiper.autoplay.stop()
			},
			/** 鼠标移出 */
			onMouseLeave() {
				// this.mySwiper.autoplay.start()
			},
			test(e) {
				// 默认会$event对象
				console.log(11, e);
			},
			homepage() {
				this.$router.push({
					path: '/'
				})
			},
			matchList() {
				let data = {
					stationId: this.$route.query.id
				}
				this.$api.matchInfoListAll(data).then(res => {
					res.data.data.forEach(item => {
					  item.isJSON = this.isJSON(item.start_time)
					  if (this.isJSON(item.start_time)) {
					    item.start_time = JSON.parse(item.start_time)
					  }
					})
					this.scheduleList = res.data.data
				})
			},
			isJSON(str) { // 判断是否json
			  if (typeof str != 'string') { // 1、传入值必须是 字符串
			    return false;
			  }
			  try {
			    var obj = JSON.parse(str); // 2、仅仅通过 JSON.parse(str)，不能完全检验一个字符串是JSON格式的字符串
			    if (typeof obj == 'object' && obj) { //3、还必须是 object 类型
			      return true;
			    } else {
			      return false;
			    }
			  } catch (e) {
			    return false;
			  }
			  return false;
			},
			getTime(dates) { // 时间转换
			  let startDate = new Date(dates[0]);
			  let endDate = new Date(dates[1]);
			  let formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
			  let formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;
			  if(formattedStartDate == formattedEndDate){
			  	return `${formattedStartDate}`;
			  }else{
			  	return `${formattedStartDate} 至 ${formattedEndDate}`;
			  }
			},
			getIndexColumn() {
				this.$api.getIndexColumn(this.$route.query.id).then(res => {
					this.allData = res.data.data
					this.articles = res.data.data[0].articles
					for (let i in res.data.data) {
						for (let x in res.data.data[i]) {
							if (res.data.data[i][x].name == '精彩瞬间') {
								this.momentsList = res.data.data[i][x].articles
							}
						}
					}
				})
			},
			goDingyue(e, title, row) {
				this.concentratetitle = title
				if (e == 1) {
					this.display = false
				} else if (e == 2) {
					this.display = false
				} else if (e == 3) {
					this.display = false
				} else if (e == 4) {
					this.display = false
				} else if (e == 5) {
					this.display = false
				}
				this.info.id = row.id
				this.getArticleListById()
			},
			currentchange(val) {
				this.info.pageNum = val
				this.getArticleListById()
			},
			getArticleListById() {
				this.$api.getArticleListById(this.info).then(res => {
					this.concentrateList = res.data.data.list
					this.total = res.data.data.total
				})
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/articleDetails',
					query: {
						id: id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
		}
	}
</script>
<style  lang="scss" scoped>
	.table ::v-deep .el-table .success-row {
		background: rgba(198, 241, 255, 0.63);
	}

	.head {
		width: 100%;
		height: 518PX;
	}

	.head .text {
		position: absolute;
		top: 125PX;
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.head .text .logo {
		width: 155PX;
		height: 99PX;
	}

	.head .text .title {
		font-size: 41PX;
		font-weight: normal;
		color: #444444;
		margin-top: 30PX;
	}

	img {
		width: 100%;
		height: 100%;
	}

	.one {
		display: flex;
		justify-content: center;
		margin-top: -140PX;
		z-index: 99999;
		position: relative;
	}

	.one .but:nth-child(1) {
		margin-left: 40PX;
	}

	.one .but {
		margin-right: 40PX;
		cursor: pointer;
	}

	.one .but .name {
		width: 260PX;
		height: 85PX;
		font-size: 28PX;
		font-weight: 500;
		color: #266DFF;
		position: absolute;
		text-align: center;
		line-height: 85PX;
		margin-left: 13PX;
	}

	.one .but .back {
		width: 275PX;
		height: 85PX;
	}

	.back-box {
		width: 100%;
		background: linear-gradient(2deg, rgba(255, 255, 255, 0.27), rgba(23, 236, 255, 0.27));
		margin-top: -47PX;
		min-height: 500PX;
	}

	.one-back {
		width: 1200PX;
		margin: 0 auto;
	}

	.return {
		right: 42PX;
		width: 90PX;
		height: 206PX;
		background: #D5ECFF;
		border-radius: 15PX;
		cursor: pointer;
		z-index: 999999;
		
		top: 50%;
		transform: translateY(-50%);
		position: fixed;
	}

	.return img {
		width: 61PX;
		height: 58PX;
		margin-top: 23PX;
		margin-left: 18PX;
	}

	.return img {
		width: 61PX;
		height: 58PX;
		margin-top: 23PX;
		margin-left: 18PX;
	}

	.return .home {
		font-size: 25PX;
		font-weight: 400;
		color: #000000;
		margin-left: 20PX;
		margin-top: 24PX;
	}

	.lun {
		width: 1200PX;
		height: 459PX;
		border-radius: 16PX;
		padding-top: 120PX;
		margin-bottom: 160PX;
	}

	.lun .main {
		position: relative;
		overflow: hidden;
		border-radius: 16PX;
	}

	.lun .lun_bo {
		overflow: hidden;
		position: absolute;
		height: 380PX;
	}

	.lun .lun_bo:hover {
		cursor: pointer;
	}

	.lun .lun_bo img {
		display: block;
		float: left;
		border-radius: 16PX;
	}

	.lun .btn_demo {
		width: 1200PX;
		height: 70PX;
		background: #07549C;
		opacity: 0.8;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1000;
		display: flex;
		align-items: center;
		padding: 0 40PX;
		justify-content: space-between;
		border-bottom-left-radius: 16PX;
		border-bottom-right-radius: 16PX;
	}

	.lun .btn_disc {
		display: flex;
		justify-content: center;
	}

	.lun .desc {
		color: #fff;
		text-align: center;
		height: 19PX;
		font-size: 18PX;
	}

	.lun a {
		width: 13PX;
		height: 13PX;
		background: #FFFFFF;
		border-radius: 50%;
		display: flex;
		margin-right: 10PX;
		cursor: pointer;
		margin-top: 13PX;
	}

	.lun a:hover {
		cursor: pointer;
	}

	.lun .direct {
		background: transparent;
		position: absolute;
		top: 200PX;
		height: 30PX;
		line-height: 25PX;
		text-align: center;
		font-size: 40PX;
		padding: 3PX;
		border: 4PX solid #fff;
		color: #fff;
		font-weight: 700;
		display: none;
	}

	.lun .left {
		left: 70PX;
	}

	.lun .right {
		right: 70PX;
	}

	.lun .direct:hover {
		cursor: pointer;
	}

	.spot {
		width: 225PX;
		height: 84PX;
		position: absolute;
		right: 20PX;
		margin-top: 80PX;
	}

	.spot2 {
		width: 225PX;
		height: 84PX;
		position: absolute;
		left: 0;
	}

	.content {
		width: 1200PX;
		margin: 0 auto;
		margin-top: 30PX;
		z-index: 9999;
		position: relative;
	}

	.title-but {}

	.title-but .title {
		width: 218PX;
		height: 37PX;
	}

	.title-but .name {
		position: absolute;
		color: #fff;
		line-height: 37PX;
		width: 218PX;
		text-align: center;
		font-size: 18PX;
		font-weight: 500;
	}

	.line {
		border-bottom: 1PX solid #0081F5;
		margin-left: 20PX;
		margin-top: -1PX;
	}

	.more {
		display: flex;
		color: #0081F5;
		font-size: 16PX;
		font-weight: 500;
		align-items: center;
		cursor: pointer;
	}

	.right {
		margin-left: 5PX;
	}

	.specific {
		margin-top: 30PX;
		font-size: 15PX;
		color: #333333;
	}

	.specific ul li {
		display: flex;
		justify-content: space-between;
		margin-bottom: 23PX;
	}

	.rounddot {
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.rounddot:hover a {
		color: #408ED6;
	}

	.round {
		width: 7PX;
		height: 7PX;
		border-radius: 100%;
		background: #000;
		box-shadow: 0 2PX 4PX 0 rgba(0, 0, 0, .2);
		margin-right: 10PX;
	}

	.swiper {
		width: 100%;
		height: 223PX;
		display: flex;
		align-items: center;
	}

	.swiper-container {
		width: 100%;
		height: 100%;
	}

	.theleftside {
		width: 34.5PX;
		height: 54.8PX;
		margin-right: 44PX;
		cursor: pointer;
	}

	.therightside {
		width: 34.5PX;
		height: 54.8PX;
		margin-left: 44PX;
		cursor: pointer;
	}

	.swiper ::v-deep .swiper-wrapper {
		display: flex;
		align-items: center
	}

	.swiper-slide {
		text-align: center;
		font-size: 18PX;

		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
		transition: 300ms;
		/* margin-right: 24PX; */
	}

	.swiper-slide-active,
	.swiper-slide-duplicate-active {
		transform: scale(1);
	}

	.information {
		width: 210PX !important;
		height: 147PX !important;
		position: absolute;
	}

	.information .title {
		width: 210PX !important;
	}

	::v-deep .swiper-slide-active {
		width: 312PX !important;
	}

	::v-deep .swiper-slide-active .information {
		width: 302PX !important;
		height: 223PX !important;
		position: relative !important;
	}

	::v-deep .swiper-slide-active .information .title {
		width: 302PX !important;
	}

	::v-deep .swiper-slide-active .information .img {
		width: 100%;
		height: 100%;
	}

	.information .title {
		position: absolute;
		width: 100%;
		height: 40PX;
		font-size: 17PX;
		font-weight: 500;
		color: #FFFFFF;
		bottom: 0;
		background: rgba(0, 0, 0, 0.4);
		border-bottom-left-radius: 16PX;
		border-bottom-right-radius: 16PX;
		line-height: 40PX;
		padding: 0 10PX;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}

	.information .img {
		width: 100%;
		height: 100%;
		border-radius: 16PX;
		border-radius: 16PX;
	}

	::v-deep .swiper-slide-active .information .title {
		height: 60PX !important;
		line-height: 60PX !important;
	}

	.paging {
		display: flex;
		align-items: center;
		margin-bottom: 20PX;
	}

	.paging span {
		margin-right: 5PX;
	}

	::v-deep .el-pager li.active {
		color: #003399;
		background: none;
	}

	::v-deep .el-pagination button:disabled {
		background: none;
	}
	
	.table {
		padding-top: 120PX;
		padding-bottom: 187PX;
		margin: 0 90PX;
	}
	
	.table ::v-deep .el-table thead {
		color: #fff;
		font-size: 16PX;
		font-weight: 500;
	}
	
	.table ::v-deep .el-table__header-wrapper {
		borderradius: 25PX;
	}
	
	.table ::v-deep .el-table {
		border-radius: 25PX;
		box-shadow: 0PX 0PX 15PX 1PX rgba(143, 217, 243, 0.34)
	}
	
	.table2 {
		padding-top: 120PX;
		padding-bottom: 187PX;
		margin: 0 90PX;
	}
	
	.table2 ::v-deep .el-table thead {
		color: #fff;
		font-size: 16PX;
		font-weight: 500;
	}
	
	.table2 ::v-deep .el-table__header-wrapper {
		borderradius: 25PX;
	}
	
	.table2 ::v-deep .el-table {
		border-radius: 25PX;
		box-shadow: 0PX 0PX 15PX 1PX rgba(143, 217, 243, 0.34)
	}
	
	// .table2 ::v-deep .el-table thead tr th:nth-child(2){
	// 	background-image: linear-gradient(#F8B65C, #FFE71D, #FFBA5B) !important;
	// }
	// .table2 ::v-deep .el-table thead tr th:nth-child(3){
	// 	background-image: linear-gradient(#EEEDEC, #D1CCCA, #F0EFEE) !important;
	// }
	// .table2 ::v-deep .el-table thead tr th:nth-child(4){
	// 	background-image: linear-gradient(#FFE5BB, #DE9D76, #FFF7D9) !important;
	// }
	
	.custom-header {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.custom-header img {
		width: 30PX;
		height: 40PX;
		margin-right: 7PX;
	}
</style>